import React from "react";
import Layout from "../components/layout";
import Nav from "../components/nav";
import SEO from "../components/seo";
import './contact.css';

const lang = {
  en: {
    header: 'Contact',
    name: 'Name',
    email: 'E-mail',
    message: 'Message',
    sendButton: 'Send',
  },
  sv: {
    header: 'Kontakt',
    name: 'Namn',
    email: 'E-post',
    message: 'Meddelande',
    sendButton: 'Skicka',
  }
}

const Contact = () => (
  <Layout>
    <SEO title="Contact" description="Contact Dalaro Dykpark: Stockholm based scuba diving park" />
    <Nav />
    <div className="contact__header"></div>
    <div className="contact__section">
      <div className="contact__form">
        <h1>{lang.en.header}</h1>
        <div className="inner">
          <form method="post" name="contact" action="/thanks" data-netlify="true" netlify-honeypot="bot" >
            <input type="hidden" name="form-name" value="contact" />
            <div className="field__hidden">
              <label htmlFor="bot">Don't fill this out, human</label>
              <input name="bot" />
            </div>
            <div className="field">
              <label htmlFor="name">{lang.en.name}</label>
              <input type="text" name="name" />
            </div>
            <div className="field">
              <label htmlFor="email">{lang.en.email}</label>
              <input type="text" name="email" />
            </div>
            <div className="field">
              <label htmlFor="message">{lang.en.message}</label>
              <textarea name="message" rows="6"></textarea>
            </div>
            <div className="submit">
              <button type="" className="btn__med">{lang.en.sendButton}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
)

export default Contact;